<template>
  <div>
    <!-- 导航栏 -->
    <!-- <van-nav-bar title="个人信息维护" left-text="返回" @click-left="onClickLeft">
      <template #left>
        <van-icon name="cross" size="18" color="black" />
      </template>
    </van-nav-bar> -->
    <!-- 表单 -->
    <van-form ref="form">
      <van-cell-group title="基本信息">
        <van-field v-model="stuInfo.stuName" placeholder="请填写姓名" :disabled="true" label="姓名" />
        <van-field v-model="stuInfo.stuNo" placeholder="请填写学号" :disabled="true" label="学号" />
        <custom-radio v-model="stuInfo.sexName" label="性别" :disabled="true" valueKey="label" :columns="sexDict" />
        <van-field v-model="stuInfo.nativePlaceName" placeholder="请填写民族" :disabled="true" label="民族" />
        <van-field v-model="stuInfo.cardNo" placeholder="请填写身份证号" :disabled="true" label="身份证号" />
        <van-field v-model="stuInfo.eduSystem" placeholder="请填写学制" :disabled="true" label="学制" />
        <van-field v-model="stuInfo.enterMon" placeholder="请填写入学年月" :disabled="true" label="入学年月" />
        <van-field v-model="stuInfo.polName" placeholder="请填写政治面貌" :disabled="true" label="政治面貌" />
        <van-field v-model="stuInfo.gradeNo" placeholder="请填写年级" :disabled="true" label="年级" />
        <van-field v-model="stuInfo.collName" placeholder="请填写院系" :disabled="true" label="院系" />
        <van-field v-model="stuInfo.sqecName" placeholder="请填写专业" :disabled="true" label="专业" />
        <van-field v-model="stuInfo.className" placeholder="请填写班级" :disabled="true" label="班级" />
        <van-field v-model="stuInfo.stuStatusName" placeholder="请填写学籍状态" :disabled="true" label="学籍状态" />
        <div class="form-tips"><span>以上数据来源于教务</span></div>
        <van-field v-model="form.beforeName" placeholder="请填写曾用名" label="曾用名" />
        <custom-date-picker required ref="bdDate" v-model="form.bdDate" label="出生日期" placeholder="点击选择出生日期" />
        <regional-cascader required ref="nativePlace" v-model="form.nativePlace" placeholder="请选择籍贯" :changeLevel="2" label="籍贯" />
        <regional-cascader required ref="bdAddrCode" v-model="form.bdAddrCode" placeholder="请选择出生地" :changeLevel="2" label="出生地" />
        <regional-cascader required ref="stuSource" v-model="form.stuSource" placeholder="请选择生源地" :changeLevel="2" label="生源地" />
        <custom-select required ref="ctryCode" v-model="form.ctryCode" placeholder="请选择国籍" label="国籍/地区码" :columns="dict.ctry_base_world_ctry_region_name" />
        <van-field v-model="form.middleSchool" placeholder="请填写中学名称" label="中学名称" />
        <custom-radio v-model="form.singleChildFlag" label="是否独生子女" :columns="isNotDict" />
        <custom-select required ref="healthCode" v-model="form.healthCode" placeholder="请选择健康状态" label="健康状态" :columns="dict.ctry_base_health_stat" />
        <custom-select required ref="householdClsCode" v-model="form.householdClsCode" placeholder="请选择户口类别" label="户口类别" :columns="dict.ctry_comn_household_cls" />
        <custom-select v-model="form.examType" placeholder="请选择考试类别" label="考试类别" :columns="dict.edu_stu_adms_code" />
        <custom-select v-model="form.planNature" placeholder="请选择计划性质" label="计划性质" :columns="dict.dlmu_hr_pd_admission_sort" />
        <custom-select v-model="form.enrollType" placeholder="请选择录取类别" label="录取类别" :columns="dict.matriculate_type" />
        <van-field v-model="form.rideBeginSite" placeholder="请填写乘车区间(始发站)" label="乘车区间(始)" />
        <van-field v-model="form.rideEndSite" placeholder="请填写乘车区间(终点站)" label="乘车区间(终)" />
        <van-field v-model="form.openAccountBank" placeholder="请填写开户行信息" label="开户行信息" />
        <van-field v-model="form.bankCardNumber" placeholder="请填写银行卡号" label="银行卡号" />
      </van-cell-group>
      <van-cell-group title="联系信息">
        <van-field required :rules="[{ required: false, message: '请填写电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" required v-model="form.personalPhone" placeholder="请填写电话" label="电话号码" />
        <van-field v-model="form.email" placeholder="请填写邮箱" label="邮箱" />
        <van-field required :rules="[{ required: true, message: '请填写QQ号' }]" required v-model="form.qqNo" placeholder="请填写QQ号" label="QQ号" />
        <van-field v-model="form.wechatNo" placeholder="请填写微信号" label="微信号" />
        <van-field v-model="form.passportNo" placeholder="请填写护照号" label="护照号" />
      </van-cell-group>
      <van-cell-group title="家庭信息">
        <regional-cascader required ref="familyAddress" v-model="form.familyAddress" placeholder="请填写家庭地址" label="家庭地址" />
        <van-field required :rules="[{ required: true, message: '请填写详细地址' }]" required v-model="form.familyFullAddress" placeholder="请填写详细地址" label="详细地址" />
        <van-field v-model="form.postCode" placeholder="请填写邮政编码" label="邮政编码" />
        <van-field required v-model="form.familyPhone" placeholder="请填写家长手机号1" label="家长手机号1" required :rules="[{ required: true, message: '请填写家长手机号1' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" />
        <van-field v-model="form.familyPhone2" placeholder="请填写家长手机号2" label="家长手机号2" />
        <custom-select ref="housingSituation" v-model="form.housingSituation" placeholder="请选择住房情况" label="住房情况" :columns="dict.xj_zfqk" />
        <custom-select ref="carSituation" v-model="form.carSituation" placeholder="请选择购车情况" label="购车情况" :columns="dict.xj_gcqk" />
      </van-cell-group>
      <van-cell-group title="家庭成员信息">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.relatedList" :key="index" :title="item.relativesName+'('+item.relationship+')'" :value="item.phone" :name="index">
            <div> <span class="collapse-title">身份证号码:</span><span class="collapse-text">{{item.idCard}}</span></div>
            <div> <span class="collapse-title">健康状态:</span><span class="collapse-text">{{codeConvertLabel(item.healthCode,dict.ctry_base_health_stat)}}</span></div>
            <div> <span class="collapse-title">工作单位:</span><span class="collapse-text">{{item.employer}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="familyDelete(index)">删除</van-button>
            <van-button style="height:26px;margin-left: 5px;margin-top: 10px;" round type="primary" plain hairline @click="familyEdit(item,index)">编辑</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" size="small" native-type="button" style="height:36px" @click="familyAdd">新增家庭成员</van-button> <span v-if="showErr" style="color:#ee0a24">至少添加一条家庭成员</span>
        </div>
      </van-cell-group>
      <van-cell-group title="个人简介">
        <van-field v-model="form.personalIntroduction" class="textarea-style" rows="3" label="个人简介" type="textarea" maxlength="300" placeholder="请输入个人简介" show-word-limit />
      </van-cell-group>
      <van-cell-group title="其他信息">
        <van-field v-model="form.strongPoint" rows="3" class="textarea-style" arrow-direction="" label="个人特长" type="textarea" maxlength="300" placeholder="请输入个人特长" show-word-limit />
        <van-field v-model="form.otherInfo" style="margin-bottom:76px;" rows="3" class="textarea-style" label="其他说明信息" type="textarea" maxlength="300" placeholder="请输入其他说明信息" show-word-limit />
      </van-cell-group>
      <div class="submit-button shadow-style">
        <van-button round block type="primary" :loading="loading" @click="doSubmit">提 交</van-button>
      </div>
    </van-form>
    <!-- 家庭成员信息弹出层 -->
    <van-popup v-model="familyInfoShow" position="bottom" closeable @close="familyclose" :style="{ height: '427px' }">
      <div class="van-popup-title-div"><b>{{isFamilyAdd?'新增家庭成员':'编辑家庭成员'}}</b></div>
      <van-form @submit="familySubmit">
        <van-field v-model="familyForm.relativesName" placeholder="请填写姓名" required label="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field required v-model="familyForm.idCard" placeholder="请填写身份证号" required label="身份证号" :rules="[{ required: true, message: '请填写身份证号' },
        {pattern:cardReg, message: '请输入合法的身份证号'}]" />
        <van-field required v-model="familyForm.relationship" required placeholder="请填写与本人关系" label="与本人关系" :rules="[{ required: true, message: '请填写与本人关系' }]" />
        <van-field v-model="familyForm.employer" placeholder="请填写工作单位" label="工作单位" />
        <custom-select v-model="familyForm.healthCode" placeholder="请选择健康状态" label="健康状态" :columns="dict.ctry_base_health_stat" />
        <van-field required v-model="familyForm.phone" required placeholder="请填写联系电话" label="联系电话" :rules="[{ required: true, message: '请填写联系电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Notify } from "vant";
import { mapGetters } from "vuex";
import {
  getStuinfo,
  getStuPersonalInfo,
  edit,
} from "@/api/modules/student/personal";
import { validCustom } from "@/utils";

export default {
  name: "StuPersonalInfo",
  components: {},
  dicts: [
    "ctry_comn_household_cls",
    "ctry_base_world_ctry_region_name",
    "xj_zfqk",
    "xj_gcqk",
    "ctry_base_health_stat",
    "edu_stu_adms_code",
    "dlmu_hr_pd_admission_sort",
    "matriculate_type",
  ],
  data() {
    return {
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isNotDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      // 家庭信息弹出层列表默认展开第几个
      activeNames: [0],
      form: {
        id: null,
        stuNo: null,
        stuName: null,
        middleSchool: null,
        beforeName: null,
        nativePlace: null,
        bdDate: null,
        bdAddrCode: null,
        stuSource: null,
        ctryCode: null,
        singleChildFlag: true,
        healthCode: null,
        householdClsCode: null,
        examType: null,
        planNature: null,
        enrollType: null,
        rideBeginSite: null,
        rideEndSite: null,
        openAccountBank: null,
        bankCardNumber: null,
        familyAddress: null,
        familyFullAddress: null,
        familyPhone: null,
        familyPhone2: null,
        postCode: null,
        housingSituation: "01",
        carSituation: "01",
        personalPhone: null,
        email: null,
        qqNo: null,
        wechatNo: null,
        passportNo: null,
        strongPoint: null,
        otherInfo: null,
        relatedList: [],
        personalIntroduction: null,
      },
      customRuleFileds: [
        "healthCode",
        "householdClsCode",
        "bdDate",
        "nativePlace",
        "bdAddrCode",
        "stuSource",
        "ctryCode",
        "familyAddress",
        "carSituation",
        "housingSituation",
      ],
      familyForm: {
        id: "",
        relativesName: "",
        relationship: "",
        idCard: "",
        employer: "",
        phone: "",
        healthCode: "",
      },
      stuInfo: {},
      params: {},
      familyInfoShow: false,
      isFamilyAdd: false,
      editIndex: 0,
      showErr: false,
      loading: false,
      cardReg:
        /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    document.title = "个人信息维护";
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  methods: {
    codeConvertLabel(code, dicts) {
      if (code) {
        return dicts
          .filter((item) => {
            return item.value + "" === code + "";
          })
          .map((item) => {
            return item.label;
          })
          .toString();
      }
      return "";
    },
    onClickLeft() {},
    initData() {
      this.form.stuNo = this.user.username;
      // 基础数据
      getStuinfo().then((data) => {
        this.stuInfo = data;
        // 自动带出出生日期
        if (!this.form.bdDate && data.cardNo) {
          let IdCard = data.cardNo;
          this.form.bdDate =
            IdCard.substring(6, 10) +
            "-" +
            IdCard.substring(10, 12) +
            "-" +
            IdCard.substring(12, 14);
        }
      });
      this.initForm();
    },
    initForm() {
      getStuPersonalInfo()
        .then((res) => {
          if (res) {
            this.form = res;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            Dialog.confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
              .then(() => {
                let params = this.form;
                params.nativePlace = JSON.parse(params.nativePlace);
                params.bdAddrCode = JSON.parse(params.bdAddrCode);
                params.stuSource = JSON.parse(params.stuSource);
                params.familyAddress = JSON.parse(params.familyAddress);
                this.loading = true;
                edit(params).then((res) => {
                  Notify({
                    type: "success",
                    message: "提交成功",
                    duration: 2000,
                  });
                  this.initForm();
                  this.loading = false;
                });
              })
              .catch(() => {
                // on cancel
              });
          } else {
            Notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    // ------家庭信息
    familySubmit() {
      this.showErr = false;
      if (this.isFamilyAdd) {
        this.form.relatedList.push(this.familyForm);
      } else {
        this.form.relatedList.splice(this.editIndex, 1, this.familyForm);
      }
      this.familyclose();
    },
    familyAdd() {
      this.isFamilyAdd = true;
      this.familyInfoShow = true;
    },
    familyEdit(data, index) {
      this.familyForm = data;
      this.editIndex = index;
      this.isFamilyAdd = false;
      this.familyInfoShow = true;
    },
    familyDelete(index) {
      this.form.relatedList.splice(index, 1);
    },
    familyclose() {
      this.familyInfoShow = false;
      this.familyForm = {
        id: "",
        relativesName: "",
        relationship: "",
        idCard: "",
        employer: "",
        phone: "",
        healthCode: "",
      };
    },
  },
};
</script>

<style lang="css" scoped>
.van-field__label {
  width: 110px;
}
.textarea-style {
  height: 100% !important;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}

.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
</style>